import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#DBB34D'
    },
    secondary: {
      main: '#666666'
    },
    textPrimary: {
      main: '#333333'
    },
    textSecondary: {
      main: '#999999'
    }
  },
  typography: {
    fontFamily: [
      "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", "Hiragino Sans", "sans-serif"
    ].join(','),
  },
  props: {
        MuiCheckbox: {
            color: "primary"
        },
        MuiRadio: {
            color: "primary"
        },
        MuiSwitch: {
            color: "primary"
        },
        MuiButton: {
            color: "primary"
        },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

export default theme